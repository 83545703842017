import { GetStaticProps } from 'next/types';
import Component from 'components/pages/Login/Login';
import { buildPage } from 'utils/pageHelper';

export default buildPage(Component, {
  fullPage: true,
  loggedInRedirect: '/dashboard',
  metaData: {
    title: 'Login',
    description: 'Login to Qmee',
  },
});

export const getStaticProps: GetStaticProps = async (context) => {
  return { props: { hideFooterCta: true } };
};
